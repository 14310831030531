define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "components": {
      "account_creation_flow": {
        "completed": {
          "sub_text": "We won’t have to ask you again later which will help us close quickly and efficiently.",
          "submit_deal": "Submit a new deal",
          "subtitle": "We have all the information we need about you, your team, and your preferences for transactions and communications.",
          "title": "You are all set up!"
        },
        "home": {
          "communication_preferences": {
            "bullets": {
              "email": "Email",
              "phone": "Phone",
              "text": "Text"
            },
            "subtitle": "Let us know how to keep you updated on your deal.",
            "title": "Communication"
          },
          "entity_details": {
            "bullets": {
              "authorized_signers": "Authorized signers",
              "entity_information": "Entity information",
              "operating_agreement": "Operating agreement",
              "wire_instructions": "Wire instructions"
            },
            "subtitle": "We'll need key information on your entities.",
            "title": "Entities"
          },
          "personal_details": {
            "bullets": {
              "citizenship_status": "Citizenship status",
              "contact_information": "Contact information",
              "date_of_birth": "DOB",
              "martial_status": "Marital status",
              "name": "Name"
            },
            "subtitle": "We’ll need the following details to set up your profile.",
            "title": "Personal details"
          },
          "subtitle": "We’ll collect a bit of information about you, your team, and your preferences for transactions and communicating with us. We won’t have to ask you again later which will help us close quickly and efficiently.",
          "team_details": {
            "bullets": {
              "email": "Email",
              "name_and_last_name": "Name and Last name",
              "phone_number": "Phone number",
              "role": "Role"
            },
            "subtitle": "Creating a team allows you to quickly add them to your deals.",
            "title": "Team members"
          },
          "title": "Great! Now let's get you setup for success."
        },
        "top_bar": {
          "save_and_exit": "Save & Exit"
        },
        "user_type": {
          "investor": "I’m a real estate investor",
          "lender": "I’m a lender",
          "non_investor": "I’m Buying/Selling my Primary Residence",
          "real_estate_agent": "I am a real estate agent",
          "subtitle": "Let’s get started by collecting a few important details about you. What brings you to Empora today?",
          "title": "Welcome to Empora, your digital title partner."
        }
      },
      "deal": {
        "action_item_completion": {
          "do_later": "I’ll do this later",
          "get_ahead": "Get ahead on your deal by completing your next task!",
          "header": "Check that one off the list",
          "just_finished": "You just finished your task “{activityDescription}”. We know your time is valuable, that’s why we try to make our portal as fast and easy for you to use as possible.",
          "move_deal_along": "Move your deal along by completing the next task in your list. This will help us make sure your closing goes smoothly.",
          "next_up": "Next up: {activityDescription}",
          "no_more_tasks": "You don’t have any more tasks to complete on this deal at this time. We’ll let you know when you need to do something next.",
          "view_deal": "View my deal"
        },
        "action_items": {
          "borrower_onboarding": {
            "completed_description": "Thank you for submitting your information. If you need to update or change your information please request a change.",
            "incomplete_description": "To kick off the process and ensure your deal stays on track, we need you to confirm some information for us. Click the button below to complete your onboarding, it only takes about five minutes.",
            "refinancing_details": "Refinancing details"
          },
          "buyer_closing_preferences_and_coverages": {
            "closing_preference_header": "Closing preference:",
            "closing_preferences": "Closing preferences",
            "closing_protection_coverage": "Closing Protection Coverage (CPC)",
            "completed_description": "Thank you for selecting your closing preferences and coverages. If you need to update or change this information please request a change.",
            "cpc_header": "Closing protection coverage:",
            "incomplete_description": "Choose your closing preferences, select your tax forwarding address, and choose your closing protection coverage.",
            "notary_preference": {
              "e_signature": "Virtually with HelloSign: Close anywhere with the click of a button, no notary needed.",
              "in_person": "At our office in Columbus, Ohio",
              "mobile_notary": "You choose the location, and we come to you.",
              "remote_online_notary": "Virtually with a Remote Online Notary"
            },
            "tax_documents_mailing_address": "Tax documents mailing address",
            "tax_forwarding_address_header": "Tax forwarding address:",
            "to_my_lender_or_mortgage_company": "To my lender or mortgage company"
          },
          "buyer_titleholder_and_financing_information": {
            "completed_description": "Thank you for submitting your information. If you need to update or change your information please request a change.",
            "incomplete_description": "In order to move your deal forward you need to add or edit who you would like to be the titleholder(s) for this property. If you are getting financing for the property through a bank or lender, let us know who they are.",
            "your_financing_header": "Your financing",
            "your_titleholders_header": "Your titleholders"
          },
          "closing_request": {
            "complete_description": "Thank you for submitting your closing request. If your preferred closing appointment needs to change for any reason we will reach out to you as soon as possible.",
            "incomplete_description": "We are ready to get your closing scheduled. Please pick a date, time, and signing method that works best for you. If your preferred closing appointment needs to change for any reason we will reach out to you as soon as possible."
          },
          "provide_rental_information": {
            "completed": "Thank you for providing copies of leases and rent rolls. If you need to update or change the leases or rent rolls on this deal, please reach out to your Empora Client Success Manager.",
            "lease_missing_error": "Must upload at least one lease copy",
            "ready_to_start": "In order to generate your settlement statement, you must provide copies of leases listing the security deposit as well as copies of rent rolls for all renters who will be staying on the property through the transaction.",
            "rent_roll_missing_error": "Must upload at least one rent roll copy",
            "rental_information_subtitle": "Please upload documents showing the most up-to-date leases, security deposits, and paid rent information for any tenants or renters who will be staying at the property after closing. This is needed to accurately and correctly prorate and credit any payments on the settlement statement.",
            "rental_information_title": "Rental Information"
          },
          "representative_complete_on_behalf": "This activity is assigned to your client. If you know the relevant information and wish to complete the activity on their behalf, please click the Start button. Otherwise, you can help by contacting the {clientRole} to remind them to complete this task.",
          "representative_request_a_change": "This activity was completed by or on behalf of your client. If you feel any of the information is inaccurate, you may request a change below.",
          "request_a_change": "Hi! I’d like to make changes to {actionItem} for this deal. Here are the changes I need: (list changes below)",
          "seller_titleholder_information": {
            "completed": "Thank you for completing your titleholder information. If you need to update or change your titleholder information on this deal, please reach out to your Empora Client Success Manager.",
            "ready_to_start": "In order to move your deal forward, please add or select the current legal owner(s) for this property."
          }
        },
        "all_deals": "All deals",
        "buyer_closing_preferences_and_coverages": {
          "closing_preference_in_person": "At our office in Columbus, Ohio",
          "closing_preference_mobile": "Mobile Notary",
          "closing_preference_mobile_description": "You choose the location, and we come to you.",
          "closing_preference_ron": "Virtually with a Remote Online Notary",
          "closing_preference_ron_description": "Close anywhere via a web meeting.",
          "closing_preference_subtitle": "In some cases, lender or technical restrictions may require that closing be completed in-person, but we’ll honor your closing preference as much as possible.",
          "closing_preference_title": "Where would you like to complete your closing?",
          "cpc_accept": "Yes, I would like CPC coverage",
          "cpc_accept_description": "Typically costs between $20-$55",
          "cpc_decline": "No, I would like to decline this coverage",
          "cpc_disclosure": "By checking this box you are acknowledging that you have read and agree to the Closing Protection Coverage Disclosure.",
          "cpc_instructions": "Please read the Closing Protection Coverage Disclosure below.",
          "cpc_label": "Closing Protection Coverage Disclosure",
          "cpc_subtitle": "Reminder: CPC covers you in the event that we commit fraud, steal, or mishandle your funds according to the closing instructions.",
          "cpc_title": "Do you want Closing Protection Coverage (CPC)?",
          "download_and_view": "Download & View",
          "tax_address_block": "If you have a lender or mortgage company, you may choose to send them your tax bill if you are planning to pay taxes from an escrow (or impound) account.",
          "tax_address_lender": "To my lender or mortgage company",
          "tax_address_other": "Other (enter below)",
          "tax_address_subtitle": "Your county auditor will send your property tax bills to this address.",
          "tax_address_title": "Where should we mail your tax documents after closing?",
          "tax_address_tooltip": {
            "paragraph_1": "An escrow account, sometimes called an impound account, can be set up by your mortgage lender to pay specific property-related expenses.",
            "paragraph_2": "A portion of your mortgage payment goes into an escrow account each month to help you pay these yearly expenses, like insurance or property taxes.",
            "paragraph_3": "Some lenders may require that you pay your taxes and insurance using escrow. Your mortgage servicer will manage the escrow account and pay these bills on your behalf. Sometimes, escrow accounts may also be required by law.",
            "title": "What is an escrow account?"
          }
        },
        "buyer_titleholder_and_financing_information": {
          "confirm_your_information_subtitle": "Fill in or confirm your user information below, this lets us know who to follow up with if we have questions about your deal.",
          "confirm_your_information_title": "Let’s confirm your information.",
          "create_titleholder_subtitle": "This means whoever you want to be legally reflected as the owner of the property.",
          "mls_survey": {
            "tooltip": {
              "paragraph_1": "Most traditional lenders require this basic survey to establish there are no buildings, easements, property access, or other encroachments that could cause boundary or title disputes in the future.",
              "paragraph_2": "While your financing may not require this, a future retail buyer’s lender most likely will.",
              "title": "What is an MLS survey?"
            }
          },
          "mls_survey_no": "No, only order an MLS survey if it is required to close the deal",
          "mls_survey_paragraph_1": "You may not be required to get a survey, but most conventional and FHA lenders require clean surveys to have clear title. We recommend getting one to ensure you have clear title both now and in the future.",
          "mls_survey_paragraph_2": "These surveys tend to cost a few hundred dollars and take 4-5 days once ordered. You can always opt to order one as you go through the deal.",
          "mls_survey_title": "Should we order a survey of the property?",
          "mls_survey_yes": "Yes, order an MLS survey on the property",
          "titleholder_selection_label": "Do you know who you would like listed on the title of the property?",
          "titleholder_selection_no": "No, I’d like to decide this at a later time.",
          "who_should_be_listed_subtitle": "Add additional people or entities who should be listed on the title of this property. Add spouses together, add non-spouses as another individual.",
          "who_should_be_listed_title": "Who should be listed on the title of this property?"
        },
        "closing_request": {
          "buyer_funds_reminder": "As the buyer, it is recommended to wire funds at least 24 - 48 hours in advance of closing, or bring a cashiers check to your closing appointment (In-person only).",
          "location": {
            "closing_location_required": "Must select or enter a closing location",
            "label": "Closing Location",
            "other_address": "Use a different location",
            "title": "Let us know your preferred closing location"
          },
          "signers": {
            "already_scheduled": "- already scheduled",
            "subtitle": "Please select the signers for this closing appointment. Scheduling more than one signing appointment will result in a signing fee.",
            "title": "Who will be at this closing?"
          },
          "signing_date": {
            "call_to_action": "Request a closing date",
            "closing_date": "Closing date",
            "date_picker": {
              "after_threshold_warning": "Appointments scheduled after 2:00 PM ET on the closing date may not be funded until the next business day",
              "all_day": "All day appointment",
              "select": "Select",
              "subtitle": "Please note closing date or time may be subject to change. Closing appointments typically take an hour and occur on the closing date. When available you may sign before your closing date.",
              "title": "Pick a date and time"
            },
            "edit": "Edit",
            "title": "Select a date and time to close"
          },
          "signing_method": {
            "subtitle": "Please select from the options available below.",
            "title": "Where would you like to complete your closing?"
          },
          "title": "Schedule your closing"
        },
        "closing_request_completion": {
          "header": "You’re all set for now!",
          "schedule_remaining_signings": "Schedule remaining signing appointments",
          "title": "We've sent your request to close to our team. If we need to make any changes we'll reach out directly to you.",
          "view_deal": "View my deal"
        },
        "deal_users": "Deal users",
        "invite_someone_to_deal": "Invite someone to your deal",
        "lender": {
          "add_lender_info_subtitle": "If you have a chosen lender, please provide any information you currently have below.",
          "cash": "I am paying with my own cash",
          "conventional_fha": "I will be taking out a mortgage from a conventional or FHA lender",
          "hard_money": "I am using a hard money lender",
          "other_financing": "I am using other financing (e.g. seller financed, subject to, etc.)",
          "private": "I am using a private lender"
        },
        "messages": {
          "document_not_available": "Document no longer available"
        },
        "no_tasks_at_the_moment": "There are no tasks for you at the moment.",
        "sections": {
          "deal_tracker": "Deal tracker",
          "documents": "Documents",
          "messages": "Messages",
          "people": "People",
          "to_do": "To-do"
        },
        "seller_titleholder_information": {
          "create_titleholder_subtitle": "Select whoever is currently the legal owner of the property."
        }
      },
      "entity": {
        "confirm_entity_details": {
          "missing_required_wire_instructions": "There are no wire instructions for this entity. You must edit the entity and add wire instructions to continue.",
          "wire_summary": "Wiring to {bankName} {accountNumber}"
        },
        "legal_entity": {
          "add_wire_instructions_button_label": "Add Wire Instructions",
          "designated_signers": {
            "add_signer": "Add Signer",
            "confirm_remove": "Are you sure you want to remove this authorized signer?",
            "no_title_error": "You must enter a title for this authorized signer",
            "no_user_selected_error": "You must select a user for this authorized signer",
            "section_subtitle1": "Select or add all authorized signers who you would like to sign the title document. You can always change this for later deals.",
            "section_subtitle2": "All signers must be listed in your entity documents, otherwise you must upload documents that authorize them as a signer, such as a signed resolution, or operating agreements of a controlling entity.",
            "section_title": "Which authorized signers will sign the title documents?",
            "select_user": "Select user",
            "select_user_from_team": "Select user from {teamName}",
            "title": "Title"
          },
          "review_entity_details": {
            "edit_entity": "Edit Entity",
            "ein_on_file": "EIN on File",
            "no_primary_document": "There is no {documentTitle} for this entity. You must edit the entity to upload your {documentTitle} to continue.",
            "review_signers_section_no_signers": "There are no authorized signers for this entity. You must edit the entity to add authorized signers before you can use this entity as a titleholder for a deal.",
            "review_signers_section_no_signers_selected": "You must select at least one authorized signer.",
            "review_signers_section_text1": "Select all authorized signers who you would like to sign the title documents. You can always change this for later deals.",
            "review_signers_section_text2": "All selected signers must be listed in your entity documents, otherwise you must upload documents that authorize them as a signer, such as a signed resolution or operating agreements of a controlling entity.",
            "review_signers_section_title": "Review signers for this deal",
            "subtitle": "If any of this information is incorrect, you must update your entity before continuing.",
            "title": "Review Entity Information"
          },
          "types": {
            "c_corp": "Corporation",
            "estate": "Estate",
            "government": "Government",
            "guardianship": "Guardianship",
            "llc": "LLC",
            "non_profit": "Non-profit",
            "partnership": "Partnership (GP/LP/LLP)",
            "receivership_conservatorship": "Receivership/Conservatorship",
            "trust": "Trust"
          },
          "wire_instruction": {
            "account_number_confirmation_label": "Confirm account number",
            "account_number_label": "Account number",
            "additional_instructions_label": "Additional wire instructions",
            "bank_name_label": "Bank name",
            "business_address_label": "Business Address",
            "business_address_tooltip": "Keeping your business safe is important, and anti-fraud regulations require your business address matches the entity’s mailing address.",
            "close_button_label": "Close without saving",
            "name_on_account_label": "Name on account",
            "name_on_account_tooltip": "Keeping your business safe is important, and the IRS requires you to have wire instructions with a matching account name for each entity to meet anti-fraud regulations.",
            "privacy_notice": "We take privacy and security very seriously and your banking information is encrypted and secure. Once entered and saved, the routing information and account number can only be retrieved for the purposes of closing the deal and as required by law.",
            "routing_number_confirmation_label": "Confirm routing information",
            "routing_number_label": "Routing information",
            "save_button_label": "Save Wire Instructions",
            "security_notice": "This information is secured using 256 bit encryption for your protection.",
            "verify_routing_notice": "Your bank may use a specific routing number for wire transfers, please verify with your bank which routing and account information to use."
          },
          "wire_instructions_additional_info": "Any updates or new wire instructions will be sent to you to verify before any money can be wired to your account.",
          "wire_instructions_subtitle": "Please provide the instructions for wiring any proceeds, if applicable.",
          "wire_instructions_title": "Provide your wire instructions"
        }
      },
      "intake": {
        "start_deal": {
          "request_subtype": {
            "assignment": "Assignment",
            "double_close": "Double Close",
            "novation": "Novation",
            "other": "Something else",
            "title": "What type of wholesale deal is this?"
          }
        }
      },
      "my_account": {
        "customer_comms": {
          "deal_email": {
            "always_text": "Always email me about deal updates or outstanding action items",
            "header": "Deal Emails",
            "only_when_necessary_text": "Only email me when absolutely necessary",
            "sub_text": "By default we will email you whenever a deal is opened with you on it, or whenever you may have outstanding action items on a deal. We also send email notifications for deal messages, but those preferences are handled separately below. Note, there are some emails, such as alerts to changes in wire instructions or account management emails, that we may have to continue sending."
          },
          "email": {
            "always_text": "Always email me about deal updates or outstanding action items",
            "header": "Emails",
            "only_when_necessary_text": "Only email me when absolutely necessary",
            "sub_text": "Let us know how you would like us to email you about your deals. Note, there are some emails, such as alerts to changes in wiring instructions or account management emails, that we may have to continue sending."
          },
          "failed_save": "We are unable to save your updates. Please try again later.",
          "message_notification_email": {
            "always_text": "Always send me email notifications when I receive portal messages",
            "header": "Message Emails",
            "only_when_necessary_text": "Only send a deal message notification when absolutely necessary",
            "sub_text": "By default we will email you whenever you receive a deal message on a deal but you can let us know if you want to receive those emails or not. We also send email notifications for outstanding action items, but those preferences are handled separately above. Note, there are some emails, such as alerts to changes in wiring instructions or account management emails, that we may have to continue sending."
          },
          "phone": {
            "always_text": "Always call me about deal updates",
            "header": "Phone Calls",
            "only_when_necessary_text": "Only call me about a deal when absolutely necessary",
            "sub_text": "Let us know how you would like us to call you about your deals. Note, there are some times that we may need to call you, for instance when something is highly time sensitive to keep a deal on track."
          },
          "portal": {
            "always_text": "Always send me portal messages on any deal updates or outstanding action items",
            "header": "Portal Messages",
            "only_when_necessary_text": "Only send a deal message when absolutely necessary",
            "sub_text": "We use messages on the Empora portal to communicate all kinds of important milestones and documents during a deal, including things like your title commitment and settlement statements. We will always send these by deal message, but you can also set preferences for other types of communication through the portal."
          },
          "successful_save": "Your updates have been saved!",
          "text": {
            "always_text": "Always text me about deal updates",
            "header": "Text Messages",
            "only_when_necessary_text": "Only text me about a deal when absolutely necessary",
            "sub_text": "Let us know how you would like us to communicate with you over text about your deals. Note, there may be some times that we need to send you text messages to keep a deal on track."
          }
        },
        "personal_details": {
          "privacy": "We take privacy seriously. SSNs and ITINs are encrypted and secure. We never share them without permission, except as required by law."
        }
      },
      "notification_banner": {
        "customer_comms": {
          "banner_text": "It’s important to set contact preferences to let us know the best way to reach you for important deal updates and in case we need anything from you to keep your deals moving forward.",
          "banner_title": "Let us know how to reach you!",
          "button_text": "Set my preferences"
        }
      },
      "onboarding": {
        "communication_preferences": {
          "always_column_header": "Always",
          "email_preference": {
            "always_aria_label": "Always send emails for opened deals or to dos",
            "header": "Emails for opened deals or to dos",
            "never_aria_label": "Never send emails for opened deals or to dos",
            "tooltip": "By default we will email you whenever a deal is opened with you on it, or whenever you may have outstanding to dos on a deal."
          },
          "message_notification_email_preference": {
            "always_aria_label": "Always send email notifications for portal messages",
            "header": "Email notifications for portal messages",
            "never_aria_label": "Never send email notifications for portal messages",
            "tooltip": "By default we will email you whenever you receive a deal message on a deal but you can let us know if you want to receive those emails or not."
          },
          "never_column_header": "Never",
          "phone_call_preference": {
            "always_aria_label": "Always make phone calls about deal updates",
            "header": "Phone calls about deal updates",
            "never_aria_label": "Never make phone calls about deal updates",
            "tooltip": "Let us know how you would like us to call you about your deals."
          },
          "portal_message_preference": {
            "always_aria_label": "Always send portal messages for deal updates or action items",
            "header": "Portal messages for deal updates or action items",
            "never_aria_label": "Never send portal messages for deal updates or action items",
            "tooltip": "We use messages on the Empora portal to communicate all kinds of important milestones and documents during a deal, including things like your title commitment and settlement statements."
          },
          "subtitle": "Let us know the best ways to get in touch with you about your deal - however, there are times, such as confirming wire instructions or time sensitive requests, where we may need to reach out by any available means. You can always change and update these on your My Account page.",
          "text_message_preference": {
            "always_aria_label": "Always send text messages about deal updates",
            "header": "Text messages about deal updates",
            "never_aria_label": "Never send text messages about deal updates",
            "tooltip": "Let us know how you would like us to communicate with you over text about your deals."
          },
          "title": "How should we contact you?"
        },
        "power_of_attorney": {
          "has_power_of_attorney": {
            "label": "This only applies in the unlikely case that the owner or titleholder is unable to make decisions about their property, due to mental or physical disability.",
            "no": "No, they will not use a Power of Attorney",
            "tooltip": {
              "content": "A power of attorney gives one or more persons the power to act on your behalf as your agent. The power may be limited to a particular activity, such as closing the sale of your home, or be general in its application. The power may give temporary or permanent authority to act on your behalf. The power may take effect immediately, or only upon the occurrence of a future event, usually a determination that you are unable to act for yourself due to mental or physical disability.",
              "trigger": "What is a power of attorney?"
            },
            "yes": "Yes, they will use a Power of Attorney"
          },
          "information": {
            "email": "Email address",
            "first_name": "First name",
            "label": "Fill in or confirm the power of attorney information below, this lets us know who to follow up with if we have questions about the deal.",
            "last_name": "Last name",
            "middle_name": "Middle name (Optional)",
            "phone_number": "Phone number (Optional)"
          },
          "title": "Will {name} require a Power of Attorney?"
        },
        "property_information": {
          "hoa_management_company": "Tell us a little bit more about your COA or HOA’s management company.",
          "hoa_question": "Is this property part of a Condo or Home Owners Association (HOA)?",
          "rental_question": "Is there a renter or tenant occupying the property through the transaction?",
          "rental_upload_leases": "Upload a copy of any leases, including security deposit amounts",
          "rental_upload_rent_rolls": "Upload a copy of your rent rolls"
        },
        "request_a_change": "Request a change",
        "welcome": {
          "borrower_subtitle": "Here’s what we have so far from your title order.",
          "borrower_title": "Let’s start your refinance process.",
          "subtitle": "We’ll collect a few important details about you and your property to help us close quickly and efficiently.",
          "title": "Now let’s get you set up for success."
        }
      },
      "referral": {
        "banner_text": "Let us know if you were referred by an existing client of ours so we can reach out and say thanks.",
        "banner_text_submit": "Thanks for letting us know who referred you to Empora Title.",
        "banner_title": "Let us know who referred you!",
        "banner_title_submit": "Referral submitted",
        "learn_more": "Learn More"
      }
    },
    "shared": {
      "add": "Add",
      "cancel": "Cancel",
      "completed": "Completed",
      "edit": "Edit",
      "email": "Email",
      "email_address": "Email address",
      "email_address_optional": "Email address (Optional)",
      "error_banner": "Whoops, looks like we’re missing some required information.",
      "first_name": "First name",
      "get_started": "Get started",
      "last_name": "Last name",
      "marital_status": "Marital status",
      "married": "Married",
      "middle_name": "Middle name",
      "myself": "Myself",
      "name": "Name",
      "name_optional": "Name (Optional)",
      "next": "Next",
      "no": "No",
      "not_completed": "Not completed",
      "not_married": "Not married",
      "not_selected": "Not selected",
      "optional": "(optional)",
      "phone_number": "Phone number",
      "phone_number_optional": "Phone number (Optional)",
      "remove": "Remove",
      "save": "Save",
      "start": "Start",
      "submit": "Submit",
      "text": "Text",
      "update": "Update",
      "uploaded": "Uploaded",
      "user": "User",
      "yes": "Yes",
      "yes_remove_it": "Yes, remove it",
      "yes_remove_them": "Yes, remove them"
    }
  }]];
  _exports.default = _default;
});